import React from "react";
import { Link } from "gatsby";
import styles from "./Privacy.module.scss";

export default ({ lang }) => {
  const email = "info@misawaya.net";

  return (
    <div className={styles.root}>
      <div className="container-sm">
        {lang === "ja" ? (
          <>
            <div className={styles.content__item}>
              <p style={{ marginBottom: "1rem" }}>
                当サイト（
                <Link to="/" className="link-ul">
                  https://misawaya.org/
                </Link>
                ）は、個人情報を取り扱うにあたり、個人情報の保護に関する法令等を遵守するとともに、本プライバシーポリシーを遵守いたします。
              </p>
              <p>
                なお、必要に応じて、本プライバシーポリシーを改定する場合があります。その際には、最新のプライバシーポリシーを本ページに掲載いたします。
              </p>
            </div>
            <h2 className={styles.content__head}>個人情報の収集</h2>
            <div className={styles.content__item}>
              <p>
                当サイトは、サイト運営、及び箕澤屋の活動に必要な範囲で個人情報を収集いたします。
              </p>
            </div>
            <h2 className={styles.content__head}>個人情報の管理・利用目的</h2>
            <div className={styles.content__item}>
              <p style={{ marginBottom: "1rem" }}>
                収集した個人情報（メールアドレスや名前等）は、下記の目的のために利用いたします。
                <br />
                1. お問い合わせ返信
                <br />
                2. ニュースレター配信
              </p>
              <p>
                なお、知り得た個人情報を第三者に開示することは、本人のご了解がある場合、または公的機関からの書面をもった請求以外にいたしません。
              </p>
            </div>
            <h2 className={styles.content__head}>アクセス解析ツールについて</h2>
            <div className={styles.content__item}>
              <p>
                当サイトでは、アクセス解析ツール{" "}
                <a
                  href="https://marketingplatform.google.com/intl/ja/about/analytics/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-ul"
                >
                  Google Analytics
                </a>
                を利用しています。Google
                Analyticsでは、トラフィックデータの収集のためにCookieを使用し、個人を特定する情報を含まずにログを収集します。
              </p>
              <p>
                Google Analyticsの利用規約に関して、詳しくは
                <a
                  href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-ul"
                >
                  Googleアナリティクス利用規約
                </a>
                をご覧ください。
              </p>
            </div>

            <h2 className={styles.content__head}>連絡先</h2>
            <div className={styles.content__item}>
              <p>
                本プライバシーポリシーに関するお問い合わせにつきましては、下記へご連絡ください。
                <br />
                <a href={"mailto:" + email} className="link-ul">
                  {email}
                </a>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={styles.content__item}>
              <p>Thanks for visiting. Misawaya respects your privacy. </p>
              <p style={{ marginBottom: "1rem" }}>
                This Privacy Statement applies to the website{" "}
                <Link to="/" className="link-ul">
                  https://misawaya.org/
                </Link>
                . By using any of Misawaya's Services, you confirm you have
                agreed to this Privacy Policy.
              </p>
              <p>
                We will revise these policies as necessary. Information after
                the revision will be posted on our website.
              </p>
            </div>
            <h2 className={styles.content__head}>Personal Information</h2>
            <div className={styles.content__item}>
              <p>
                We only collect personal information that is necessary to run
                Misawaya, fulfil our obligations to you.
              </p>
            </div>
            <h2 className={styles.content__head}>
              How we use your personal information
            </h2>
            <div className={styles.content__item}>
              <p style={{ marginBottom: "1rem" }}>
                We use your information for the following purposes:
                <br />
                1. Replying email
                <br />
                2. Communicating with you through Newsletter
              </p>
              <p>
                We will not sell your personal information to any third parties
                or external organizations.{" "}
              </p>
              We do not share personally identifiable information including your
              name and email address with other companies for marketing purposes
              unless the user agreed to that sharing or required to do so by
              law.
            </div>
            <h2 className={styles.content__head}>Google Analytics</h2>
            <div className={styles.content__item}>
              <p>
                We use{" "}
                <a
                  href="https://marketingplatform.google.com/about/analytics/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-ul"
                >
                  Google Analytics
                </a>{" "}
                to help us understand how our services are used, but no
                personally identifiable data including your name or email
                address is shared.{" "}
                <a
                  href="https://marketingplatform.google.com/about/analytics/terms/us/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-ul"
                >
                  Click to see Google Analytics Terms of Service
                </a>
                . You can review{" "}
                <a
                  href="https://privacy.google.com/businesses/compliance/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-ul"
                >
                  Google's compliance page here
                </a>
                .
              </p>
            </div>

            <h2 className={styles.content__head}>How to contact us</h2>
            <div className={styles.content__item}>
              <p>
                If you have any queries about our privacy policy, please email{" "}
                <a href={"mailto:" + email} className="link-ul">
                  {email}
                </a>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
