import React from "react";

import Layout from "../../components/Layout";
import Privacy from "../../components/Privacy/Privacy";

const PrivacyPage = ({ location }) => {
  const lang = "en";

  // Page setting
  const pageTitle = "Privacy Policy";
  const pageSlug = "privacy";
  const pageKeywords = [`Privacy Policy`];
  const pageDescription = "Misawaya Privacy Policy";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-sm">{pageTitle}</h1>
      </div>
      <article className="privacy-content">
        <Privacy lang={lang} />
      </article>
    </Layout>
  );
};

export default PrivacyPage;
